import {Component, HostListener, Inject, OnInit} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { PaymentConfigModel } from "../../core/models/payment-config.model";
import { PaymentezResponseSdkModel } from "../../core/models/paymentez/paymentez-response-sdk.model";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { SessionStorageService } from "../../core/services/session-storage.service";

import * as CryptoJS from "crypto-js";
import { ConfigurationService } from "../../core/services/configuration.service";
import { AuthModel } from "../../core/models/auth.model";
import { PaymentezService } from "../../core/services/paymentez.service";
import { PaymentezResponseStatusTransferModel } from "../../core/models/paymentez/paymentez-response-status-transfer.model";
import { LoadingScreenService } from "../../core/services/loading-screen.service";
import { OrderPaymentezModel } from "../../core/models/paymentez/order-paymentez.model";
import { CreditCardPaymentezUtility } from "../../core/utilities/credit-card-paymentez.utility";
import { UserCardService } from "../../core/services/user-card.service";
import { EpaycoService } from "../../core/services/epayco.service";
import { PayDiraService } from "../../gateway/paymentez/paymentez-dialog/pay-dira/pay-dira.service";
import { PayClaroPayService } from "../../gateway/paymentez/paymentez-dialog/pay-claro-pay/pay-claro-pay.service";
import { EncryptService } from "../../core/services/encrypt.service";

declare var jsPDF;
declare var window: any;
//import * as jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { ScriptUtility } from "../../core/utilities/script.utility";
import { AuthService } from "src/app/core/services/auth.service";
import { environment } from "src/environments/environment";

import { PaymentMethodService } from "src/app/core/services/payment-method.service";
import { DatalayerService } from "src/app/core/services/datalayer.service";
import { timer, Subscription } from "rxjs";
import { AuditService } from "src/app/core/services/audit.service";
import { DiraPayInvoiceResponse } from "src/app/gateway/paymentez/paymentez-dialog/pay-dira/pay-dira.interface";
import { PostMessageService } from "../../core/services/post-message.service";
import {
  ClaroResponse,
  DataResponse,
} from "../../gateway/paymentez/paymentez-dialog/pay-claro-pay/pay-claro-pay.interface";
import {DOCUMENT} from '@angular/common';
import {IdleService} from "../../core/services/idle.service";

interface postMessage {
  fn: string;
  message: {
    key: string,
    data: string,
    tokenpm?: string
  };
}
@Component({
  selector: "app-payment-response",
  templateUrl: "./payment-response.component.html",
  styleUrls: ["./payment-response.component.scss"],
})
export class PaymentResponseComponent implements OnInit {
  paymentRequest: PaymentConfigModel;
  response: PaymentezResponseSdkModel;
  responsePse: PaymentezResponseStatusTransferModel;
  diraPayInfo: DiraPayInvoiceResponse;
  claroPayInfo: DataResponse;
  credit: boolean;
  davipuntos: boolean;
  nequi: boolean;
  puntosColombia: boolean;
  diraPay: boolean;
  claroPay: boolean;
  styleUrl: SafeResourceUrl;
  loadCss = false;
  responsePdf: any;
  logo: any;
  status: string = "";
  service: any;
  checkout: boolean;
  resultPayment: boolean;
  url_return: any;
  counter = 15;
  tick = 1000;
  auth;
  countDown: Subscription;

  constructor(
    private _router: Router,
    private activateRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private sessionStorageService: SessionStorageService,
    private configurationService: ConfigurationService,
    private paymentezService: PaymentezService,
    private loadingScreenService: LoadingScreenService,
    private userCardService: UserCardService,
    private authService: AuthService,
    private paymentMethodService: PaymentMethodService,
    private epaycoService: EpaycoService,
    private datalayerService: DatalayerService,
    private auditService: AuditService,
    private payDiraService: PayDiraService,
    private payClaroPayService: PayClaroPayService,
    private postMessageService: PostMessageService,
    private encryptService: EncryptService,
    @Inject(DOCUMENT) private document: Document,
    private idleService: IdleService,
  ) {
    let refPayco = null;
    let reference = null;
    let idempotencyToken = null;
    let data = null;

    this.activateRoute.queryParams.subscribe((params) => {
      refPayco = params["ref_payco"];
      reference = params["reference"];
      idempotencyToken = params["idempotencyToken"];
      data = params["data"];
    });

    if (refPayco) {
      this.checkoutResponse(refPayco);
      return;
    }
    if (idempotencyToken && data) {
      this.claroPayResponse(idempotencyToken, data);
      return;
    }

    if (reference) {
      this.diraPayResponse(reference);
      return;
    }

    if (
      this._router.getCurrentNavigation().extras !== undefined &&
      this._router.getCurrentNavigation().extras.state !== undefined &&
      this._router.getCurrentNavigation().extras.state.paymentRequest !==
      undefined &&
      this._router.getCurrentNavigation().extras.state.response !== undefined
    ) {
      if (
        this._router.getCurrentNavigation().extras.state.response.transaction
          .puntosColombia
      ) {
        this.puntosColombiaResponse();
      } else if (
        this._router.getCurrentNavigation().extras.state.response.transaction
          .davipuntos
      ) {
        this.davipuntosResponse();
      } else if (
        this._router.getCurrentNavigation().extras.state.response.transaction
          .nequi
      ) {
        this.nequiResponse();
      } else {
        this.creditResponse();
      }
    } else {
      this.debitResponse();
    }
  }

  ngOnInit() {
    this.loadSurvey();
    this.postMessageService.send();
    const auth = this.sessionStorageService.getItem(
      SessionStorageService.ORDEN
    );
    this.auth = this.sessionStorageService.getItem<AuthModel>(
      SessionStorageService.AUTH
    );
    if (auth) {
      this.url_return = auth["url_return"];
    }

    this.counter = this.paymentRequest.countdownPurchaseSummary ?? 15;
  }

  printCreditCard() {
    window.scroll(0, 0);
    const data = document.getElementById("pdf");
    html2canvas(data).then((canvas) => {
      const imgWidth = 208;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");

      const pdf = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: 'letter',
        compress: true
      })
      const position = 0;

      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
      pdf.save(this.paymentRequest.data.reference + "_" + Date.now() + ".pdf"); // Generated PDF

      const base64Data = pdf.output("datauristring");
      //separar por ;
      let dataBase64 = base64Data.split(";");

      dataBase64 = dataBase64.filter((d) => d !== "filename=generated.pdf");
      dataBase64 = dataBase64.join(";");

      const message: postMessage = {
        fn: "appDownLoadFile",
        message: {
          key: this.paymentRequest.data.reference + "_" + Date.now(),
          data: dataBase64,
        },
      };
      this.postMessageService.send(true, message);

    });
  }

  private creditResponse() {
    this.credit = true;
    this.paymentRequest =
      this._router.getCurrentNavigation().extras.state.paymentRequest;
    this.response = this._router.getCurrentNavigation().extras.state.response;
    this.validateRedirect();
    if (this.response.transaction.plan) {
      this.response.transaction.plan.recurrence =
        this.epaycoService.recurrenceSubscription(
          this.response.transaction.plan.recurrence
        );
    }
    this.responsePdf = this.response;
    this.styleUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.paymentRequest.url_style
    );
    this.loadCss = true;
    //ScriptUtility.setGoogleTagManager('GTM-N7F7TW7');
    const googleTagManager = this.sessionStorageService.getItem(
      SessionStorageService.GOOGLE_TAG_MANAGER
    );
    try {
      (<any>window).ga("remove");
      (<any>window).ga("create", googleTagManager, "auto");
      (<any>window).ga("set", "page", "/" + window.location.href);
      (<any>window).ga("send", "pageview");
    } catch (error) {
      console.log("Fallo al instanciar google tagmanager");
      console.log(error);
    }
    this.status = CreditCardPaymentezUtility.getValueResult(
      this.responsePdf.transaction.status
    );
    let logo = this.paymentRequest.url_icon.split("/");
    this.logo = "./assets/img/" + logo[logo.length - 1];
    const productsAnalytics = this.sessionStorageService.getItem(
      SessionStorageService.PRODUCTSANALYTICS
    );
    this.paymentRequest.productsAnalyticsObject = productsAnalytics
      ? productsAnalytics
      : null;
  }

  private debitResponse() {
    let domain = this.sessionStorageService.getItem<string>(
      SessionStorageService.DOMAIN
    );
    let transactionId = this.sessionStorageService.getItem<string>(
      SessionStorageService.TRANSACTION_ID
    );
    let orderPaymentezModel =
      this.sessionStorageService.getItem<OrderPaymentezModel>(
        SessionStorageService.ORDEN
      );
    let google_tag_manager = this.sessionStorageService.getItem<string>(
      SessionStorageService.GOOGLE_TAG_MANAGER
    );

    if (
      domain !== null &&
      domain !== undefined &&
      transactionId !== null &&
      transactionId !== undefined &&
      orderPaymentezModel !== null &&
      orderPaymentezModel !== undefined
    ) {
      let auth = this.sessionStorageService.getItem<AuthModel>(
        SessionStorageService.AUTH
      );

      this.credit = false;

      this.loadingScreenService.startLoading();
      orderPaymentezModel.mixed_purchase = true;
      this.configurationService
        .getConfiguration(
          {
            url: domain,
            data: "",
            subclient: orderPaymentezModel.subclient
              ? orderPaymentezModel.subclient
              : null,
            paylink: orderPaymentezModel,
          },
          "Bearer " + auth.token
        )
        .subscribe(
          (res) => {
            this.paymentRequest = res.data;
            this.paymentRequest.data = orderPaymentezModel;
            this.service = this.paymentMethodService.gateway(
              this.paymentRequest.gateway_active
            );
            this.paymentRequest.is_paylink = orderPaymentezModel.pay_link
              ? true
              : null;

            ScriptUtility.setGoogleTagManager(google_tag_manager);
            ScriptUtility.setGoogleTagManagerNoScript(google_tag_manager);

            this.styleUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.paymentRequest.url_style
            );
            this.loadCss = true;

            let logo = this.paymentRequest.url_icon.split("/");
            this.logo = "./assets/img/" + logo[logo.length - 1];

            let subclient = this.paymentRequest.data.subclient
              ? this.paymentRequest.data.subclient
              : "";
            this.service
              .seeTransactionStatus(
                auth.token,
                this.paymentRequest,
                transactionId
              )
              .then((res) => {
                this.responsePse = res;
                this.response = this.responsePse;
                this.validateRedirect();
                if (this.response.transaction.plan) {
                  this.response.transaction.plan.recurrence =
                    this.epaycoService.recurrenceSubscription(
                      this.response.transaction.plan.recurrence
                    );
                }
                this.responsePdf = this.responsePse;
                this.status = CreditCardPaymentezUtility.getValueResult(
                  this.responsePdf.transaction.status
                );
                const productsAnalytics = this.sessionStorageService.getItem(
                  SessionStorageService.PRODUCTSANALYTICS
                );
                this.paymentRequest.productsAnalyticsObject = productsAnalytics
                  ? productsAnalytics
                  : null;

                if (this.responsePse.transaction.status == "APROBADO") {
                  this.datalayerService.purchaseEvent(
                    this.responsePse.transaction.id,
                    this.paymentRequest,
                    "",
                    "PSE"
                  );
                }
              });
          },
          (err) => {
            this.loadingScreenService.stopLoading();
            this._router.navigate(["/pagos/mensaje"], {
              state: { message: err },
            });
          }
        );
    } else {
      this._router.navigate(["/pagos/mensaje"], {
        state: { message: "Se ha presentado un error con esta solicitud" },
      });
    }
  }

  private checkoutResponse(refPayco) {
    this.loadingScreenService.startLoading();
    this.checkout = true;

    const domain = this.sessionStorageService.getItem<string>(
      SessionStorageService.DOMAIN
    );
    const orderPaymentezModel =
      this.sessionStorageService.getItem<OrderPaymentezModel>(
        SessionStorageService.ORDEN
      );
    const google_tag_manager = this.sessionStorageService.getItem<string>(
      SessionStorageService.GOOGLE_TAG_MANAGER
    );

    if (domain && refPayco && orderPaymentezModel) {
      const auth = this.sessionStorageService.getItem<AuthModel>(
        SessionStorageService.AUTH
      );
      this.configurationService
        .getConfiguration(
          {
            url: domain,
            data: "",
            subclient: orderPaymentezModel.subclient
              ? orderPaymentezModel.subclient
              : null,
          },
          "Bearer " + auth.token
        )
        .subscribe(
          (res) => {
            this.paymentRequest = res.data;
            this.paymentRequest.data = orderPaymentezModel;
            this.service = this.paymentMethodService.gateway(
              this.paymentRequest.gateway_active
            );
            ScriptUtility.setGoogleTagManager(google_tag_manager);
            ScriptUtility.setGoogleTagManagerNoScript(google_tag_manager);
            this.styleUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.paymentRequest.url_style
            );
            this.loadCss = true;
            let logo = this.paymentRequest.url_icon.split("/");
            this.logo = "./assets/img/" + logo[logo.length - 1];

            this.epaycoService
              .checkStatusTransaction(refPayco, auth.token)
              .subscribe(
                (respuesta) => {
                  this.loadingScreenService.stopLoading();
                  this.responsePse = respuesta;
                  this.response = this.responsePse;
                  this.validateRedirect();
                  if (this.response.transaction.plan) {
                    this.response.transaction.plan.recurrence =
                      this.epaycoService.recurrenceSubscription(
                        this.response.transaction.plan.recurrence
                      );
                  }
                  this.responsePdf = this.responsePse;
                  if (
                    this.responsePdf &&
                    this.responsePdf.transaction &&
                    this.responsePdf.transaction.status
                  ) {
                    this.status = CreditCardPaymentezUtility.getValueResult(
                      this.responsePdf.transaction.status
                    );
                  }
                },
                (error) => {
                  this.loadingScreenService.stopLoading();
                  console.log(error);
                }
              );
          },
          (err) => {
            this.loadingScreenService.stopLoading();
            this._router.navigate(["/pagos/mensaje"], {
              state: { message: err },
            });
          }
        );
    } else {
      this._router.navigate(["/pagos/mensaje"], {
        state: { message: "Se ha presentado un error con esta solicitud" },
      });
    }
  }

  private davipuntosResponse() {
    this.davipuntos = true;
    this.paymentRequest =
      this._router.getCurrentNavigation().extras.state.paymentRequest;
    this.response = this._router.getCurrentNavigation().extras.state.response;
    this.validateRedirect();

    // this.responsePdf = this.response;
    this.styleUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.paymentRequest.url_style
    );
    this.loadCss = true;
  }

  private nequiResponse() {
    this.nequi = true;

    this.paymentRequest =
      this._router.getCurrentNavigation().extras.state.paymentRequest;
    this.response = this._router.getCurrentNavigation().extras.state.response;
    this.status = this.response.transaction.status;
    if (this.status == "approved") this.status = "APROBADO";
    if (this.status == "pending") this.status = "PENDIENTE";
    if (this.status == "timeout") this.status = "RECHAZADO";

    this.validateRedirect();

    let google_tag_manager = this.sessionStorageService.getItem<string>(
      SessionStorageService.GOOGLE_TAG_MANAGER
    );
    ScriptUtility.setGoogleTagManager(google_tag_manager);
    ScriptUtility.setGoogleTagManagerNoScript(google_tag_manager);
    const productsAnalytics = this.sessionStorageService.getItem(
      SessionStorageService.PRODUCTSANALYTICS
    );
    this.paymentRequest.productsAnalyticsObject = productsAnalytics
      ? productsAnalytics
      : null;

    if (this.status == "APROBADO") {
      this.datalayerService.purchaseEvent(
        this.response.transaction.nequi.transactionID,
        this.paymentRequest,
        "",
        "NEQUI"
      );
    }

    this.styleUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.paymentRequest.url_style
    );
    this.loadCss = true;
  }

  private puntosColombiaResponse() {
    this.puntosColombia = true;
    this.paymentRequest =
      this._router.getCurrentNavigation().extras.state.paymentRequest;
    this.response = this._router.getCurrentNavigation().extras.state.response;
    this.validateRedirect();

    let google_tag_manager = this.sessionStorageService.getItem<string>(
      SessionStorageService.GOOGLE_TAG_MANAGER
    );
    ScriptUtility.setGoogleTagManager(google_tag_manager);
    ScriptUtility.setGoogleTagManagerNoScript(google_tag_manager);
    const productsAnalytics = this.sessionStorageService.getItem(
      SessionStorageService.PRODUCTSANALYTICS
    );
    this.paymentRequest.productsAnalyticsObject = productsAnalytics
      ? productsAnalytics
      : null;

    if (this.response.transaction.status == "success") {
      this.datalayerService.purchaseEvent(
        this.response.transaction.puntosColombia.transactionId,
        this.paymentRequest,
        "",
        "PUNTOS_COLOMBIA_REDENCION"
      );
    }

    this.styleUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.paymentRequest.url_style
    );
    this.loadCss = true;
  }

  private diraPayResponse(reference) {
    let domain = this.sessionStorageService.getItem<string>(
      SessionStorageService.DOMAIN
    );
    let transactionId =
      this.sessionStorageService.getItem<string>(
        SessionStorageService.TRANSACTION_ID
      ) ?? "efe5dd97-0e8b-4823-a7ce-730810a869f3";
    let orderPaymentezModel =
      this.sessionStorageService.getItem<OrderPaymentezModel>(
        SessionStorageService.ORDEN
      );
    let google_tag_manager = this.sessionStorageService.getItem<string>(
      SessionStorageService.GOOGLE_TAG_MANAGER
    );

    if (
      domain !== null &&
      domain !== undefined &&
      transactionId !== null &&
      transactionId !== undefined &&
      orderPaymentezModel !== null &&
      orderPaymentezModel !== undefined
    ) {
      let auth = this.sessionStorageService.getItem<AuthModel>(
        SessionStorageService.AUTH
      );

      this.loadingScreenService.startLoading();
      this.payDiraService.checkInvoice(reference, auth.token).subscribe(
        (dira) => {
          this.diraPayInfo = dira;
          this.status = dira.status;
          this.configurationService
            .getConfiguration(
              {
                url: domain,
                data: "",
                subclient: orderPaymentezModel.subclient
                  ? orderPaymentezModel.subclient
                  : null,
              },
              "Bearer " + auth.token
            )
            .toPromise()
            .then((res) => {
              this.diraPay = true;
              this.paymentRequest = res.data;
              this.paymentRequest.data = orderPaymentezModel;
              this.paymentRequest.is_paylink = orderPaymentezModel.pay_link
                ? true
                : null;
              this.styleUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                this.paymentRequest.url_style
              );
              this.loadCss = true;
              let logo = this.paymentRequest.url_icon.split("/");
              this.logo = "./assets/img/" + logo[logo.length - 1];

              this.validateRedirect();
              ScriptUtility.setGoogleTagManager(google_tag_manager);
              ScriptUtility.setGoogleTagManagerNoScript(google_tag_manager);
              const productsAnalytics = this.sessionStorageService.getItem(
                SessionStorageService.PRODUCTSANALYTICS
              );
              this.paymentRequest.productsAnalyticsObject = productsAnalytics
                ? productsAnalytics
                : null;

              if (this.status == "APROBADO") {
                this.datalayerService.purchaseEvent(
                  this.diraPayInfo.diraId,
                  this.paymentRequest,
                  "",
                  "DIRAPAY"
                );
              }

              this.loadingScreenService.stopLoading();
            });
        },
        (err) => {
          this.loadingScreenService.stopLoading();
          this._router.navigate(["/pagos/mensaje"], {
            state: { message: err },
          });
        }
      );
    }
  }

  private async claroPayResponse(reference, data) {
    const domain = document.location.origin;

    const res = await this.authService.login(domain).toPromise();
    if (!res.token) {
      this._router.navigate(["/pagos/mensaje"], {
        state: { message: res["msg"] },
      });
      return false;
    }

    const orderDecrypted = this.encryptService.decryptData(data);
    const orderPaymentezModel = orderDecrypted;

    if (
      domain !== null &&
      domain !== undefined &&
      orderPaymentezModel !== null &&
      orderPaymentezModel !== undefined
    ) {
      this.loadingScreenService.startLoading();
      const auth = res;
      const token = auth.token;

      this.payClaroPayService.confirmDelivery(reference, token).subscribe(
        (responseEncrypted) => {
          const response: DataResponse = this.encryptService.decryptData(
            responseEncrypted.data
          );
          this.claroPay = true;
          this.claroPayInfo = response;
          this.configurationService
            .getConfiguration(
              {
                url: domain,
                data: "",
                subclient: orderPaymentezModel.subclient
                  ? orderPaymentezModel.subclient
                  : null,
              },
              `Bearer ${auth.token}`
            )
            .toPromise()
            .then((res) => {
              this.paymentRequest = res.data;
              this.paymentRequest.data = orderPaymentezModel;
              this.paymentRequest.is_paylink = orderPaymentezModel.pay_link
                ? true
                : null;
              this.styleUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                this.paymentRequest.url_style
              );
              this.loadCss = true;
              let logo = this.paymentRequest.url_icon.split("/");
              this.logo = "./assets/img/" + logo[logo.length - 1];

              let google_tag_manager = this.paymentRequest.gtm;
              ScriptUtility.setGoogleTagManager(google_tag_manager);
              ScriptUtility.setGoogleTagManagerNoScript(google_tag_manager);

              if (this.claroPayInfo.status == "delivered" || this.claroPayInfo.status == "paid") {
                this.datalayerService.purchaseEvent(
                  this.claroPayInfo.idempotency_token,
                  this.paymentRequest,
                  auth,
                  "CLAROPAY"
                );
              }

              this.validateRedirect();
              this.loadingScreenService.stopLoading();
            });
        },
        (err) => {
          this.loadingScreenService.stopLoading();
          console.log(err.error.error);
          this._router.navigate(["/pagos/mensaje"], {
            state: { message: err.error.error.error_message },
          });
        }
      );
    }
  }
  validateRedirect() {
    this.idleService.activateIdle(this.paymentRequest.idleTime.idle,this.paymentRequest.idleTime.timeout);
    if (
      this.response &&
      (this.response.transaction.status == "success" ||
        this.response.transaction.status == "approved" ||
        this.response.transaction.status == "APROBADO" ||
        this.status == "APPROVED")
    ) {
      this.resultPayment = true;

      this.countDown = timer(0, this.tick).subscribe(() => {
        --this.counter;
        if (this.counter <= 0) {
          this.countDown.unsubscribe();
          this.redirect();
        }
      });
    } else {
      this.resultPayment = false;
    }
  }

  redirect() {
    const request = {
      dev_reference: this.paymentRequest.data.reference,
      client: this.paymentRequest.data.client,
      subclient: this.paymentRequest.data.subclient,
      leave_type: "Salida por Redirección",
      payment_status: "APROBADO",
      leave_page: true,
    };
    const auth = this.sessionStorageService.getItem<AuthModel>(
      SessionStorageService.AUTH
    );
    this.auditService.saveAudit(request, auth.token).subscribe((res) => {
      window.location.href = this.url_return;
    });
  }

  @HostListener('window:click', ['$event'])
  clickOnButton($event) {
    if ($event.target.matches('.print-tag, .print-tag *') || $event.target.id == 'volver-al-comercio' ) {
      this.countDown.unsubscribe();
      this.resultPayment = false;
    }
  }

  loadSurvey() {
    ((c, l, a, r, i, t, y) => {
      c[a] = c[a] || function () {
        (c[a].q = c[a].q || []).push(arguments);
      };
      t = l.createElement(r);
      t.async = 1;
      t.src = `https://survey.survicate.com/workspaces/${i}/web_surveys.js`;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, this.document, 'clarity', 'script', '1914b936930aa64833b1e9a3a6a0caea');
  }

  @HostListener('click', ['$event'])
  @HostListener('wheel', ['$event'])
  @HostListener('document:keydown', ['$event'])
  @HostListener('mousemove', ['$event'])
  onEvent(event: Event) {
    if (this.idleService.getIsIdle()){
      this.idleService.sendPostMessage();
      this.idleService.IdleWatch();
    }
  }


}
